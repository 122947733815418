import { useSetLanguage, useCurrentLanguage } from "@tolgee/react";
import React from "react";
import "./Footer.scss";

const Footer: React.FC = () => {
  const setLanguage = useSetLanguage();
  const getLanguage = useCurrentLanguage();

  return (
    <div className="footer">
      <select
        onChange={(e) => setLanguage(e.target.value)}
        value={getLanguage()}
      >
        <option value="en">English</option>
        <option value="de-AT">German</option>
      </select>
    </div>
  );
};

export default Footer;
