import React from "react";
import { useTranslate } from "@tolgee/react";
import "./Header.scss";

const Header: React.FC = () => {
  const t = useTranslate();

  return (
    <div className="header">
      <p>
        {t("app.name")} - {t("user.full_name")}
      </p>
    </div>
  );
};

export default Header;
